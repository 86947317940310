import { useState, useEffect, useRef } from "react";
import Styles from './styles';
import { PC, Mobile } from "hooks/useMediaQuery";

// img
import IconPlus from "resources/image/icon/icon_plus.svg";
import BnnrImg from "resources/image/main/main_banner_img.png";
import MbBnnrImg from "resources/image/main/mb_main_banner_img.png";

function MainBanner() {
  const [acitve, setAcitve] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setAcitve(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.8,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Styles.MainBanner ref={elementRef} className={acitve ? 'active' : ''}>
      <div className='bnnr_inner'>
        <div className='bnnr_tit'>
          <div className='line'>
            <i className='top'></i>
            <i className='left'></i>
            <i className='right'></i>
          </div>
          <p>마약사건 직접 수사·재판한 <span><b>검사출신 변호사</b>들로 구성된</span></p>
          <h2><strong>법무법인 온강</strong></h2>
          <button className='btn_more' onClick={()=>{ window.location.href = "/example" }}>성공사례 확인하기<img src={IconPlus} alt="more view" /></button>
        </div>
      </div>
      <PC><img src={BnnrImg} className='bnnr_img' alt="법무법인 온강" /></PC>
      <Mobile><img src={MbBnnrImg} className='bnnr_img' alt="법무법인 온강" /></Mobile>
    </Styles.MainBanner>
  );
}

export default MainBanner;