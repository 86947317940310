// ---------------------
// ---------------------
// Start:: -- COMMON ---
// ---------------------
// ---------------------

export const ICON = {
  ICON_LINE: require("resources/image/icon/line.svg").default,
  ICON_MBLINE: require("resources/image/icon/line_mb.svg").default,
  ICON_SEARCH: require("resources/image/icon/icon_search.svg").default,
  ICON_HOME: require("resources/image/icon/icon_home.svg").default,
  ICON_RIGHT: require("resources/image/icon/icon_arrow-right.svg").default,
  ICON_WHITE_LOGO: require("resources/image/icon/white-logo.svg").default,
  ICON_PLUS_BK: require("resources/image/icon/icon_plus_bk.svg").default,
  ICON_PLUS_WHITE: require("resources/image/icon/icon_plus.svg").default,
  ICON_CLOSE_WHITE: require("resources/image/icon/icon_close_white.svg").default,
};

export const IMG = {
  // UPLOAD_EX: "/assets/img/icon/upload-ex.png",
  MEMBER: require("resources/image/intro/member-total.jpg"),
  // MEMBER: require("resources/image/intro/member.png"),
  BRAND_LOGO: require("resources/image/intro/intro-brand-logo.png"),
  TYPO: require("resources/image/intro/intro-typo.png"),
  MB_TYPO: require("resources/image/intro/mb-intro-typo.png"),

  ExImg: require("resources/image/slide/ex-img-swiper.jpg"),
  SPECIAL_LECTURE: require("resources/image/intro/special-lecture.png"),
  SPECIAL_COUNSELOR: require("resources/image/intro/special-counselor.jpg"),
  SPECIAL_HAIR: require("resources/image/intro/special-hair.jpg"),
  ROYAL_EX: require("resources/image/intro/royal_ex-img.jpg"),
  ROYAL_DETAIL_EX: require("resources/image/intro/expert-detail-royal.png"),

  ROYAL_01: require("resources/image/intro/royal_01.png"),
  ROYAL_02: require("resources/image/intro/royal_02.png"),
  ROYAL_03: require("resources/image/intro/royal_03.png"),
  ROYAL_04: require("resources/image/intro/royal_04.png"),
  ROYAL_05: require("resources/image/intro/royal_05.png"),

  STROY_SW_HOVER_IMG: require("resources/image/story/story_hover_img.png"),
  STORY_SW_EX_IMG: require("resources/image/story/story-ex-img.jpg"),

  SITUATION_TAB_MEDICATION: require("resources/image/situation/situation_tab_medication.png"),
  SITUATION_TAB_SALES: require("resources/image/situation/situation_tab_sales.png"),
  SITUATION_TAB_SMUGGLING: require("resources/image/situation/situation_tab_smuggling.png"),
  SITUATION_TAB_ARREST: require("resources/image/situation/situation_tab_arrest.png"),

  MB_SITUATION_TAB_MEDICATION: require("resources/image/situation/mb-situation_tab_medication.jpg"),
  MB_SITUATION_TAB_SALES: require("resources/image/situation/mb-situation_tab_sales.jpg"),
  MB_SITUATION_TAB_SMUGGLING: require("resources/image/situation/mb-situation_tab_smuggling.jpg"),
  MB_SITUATION_TAB_ARREST: require("resources/image/situation/mb-situation_tab_arrest.jpg"),

  FOCUS_01: require("resources/image/situation/focus_01.jpg"),
  FOCUS_02: require("resources/image/situation/focus_02.jpg"),
  FOCUS_03: require("resources/image/situation/focus_03.jpg"),
  FOCUS_04: require("resources/image/situation/focus_04.jpg"),
  FOCUS_05: require("resources/image/situation/focus_05.jpg"),
  FOCUS_06: require("resources/image/situation/focus_06.jpg"),
  FOCUS_07: require("resources/image/situation/focus_07.jpg"),

  PROCESS_01: require("resources/image/situation/process_01.jpg"),
  PROCESS_02: require("resources/image/situation/process_02.jpg"),
  PROCESS_03: require("resources/image/situation/process_03.jpg"),
  PROCESS_04: require("resources/image/situation/process_04.jpg"),

  DIALOG_LOGO: require("resources/image/situation/dialog-hover-logo.png"),

  HOVER_LEFT: require("resources/image/situation/hover_1.png"),
  HOVER_TOP: require("resources/image/situation/hover_2.png"),
  HOVER_RIGHT: require("resources/image/situation/hover_3.png"),
  HOVER_BOTTOM: require("resources/image/situation/hover_4.png"),

  TRIANGLE: require("resources/image/situation/triangle-bg.png"),
};

// ---------------------
// Start:: -------------
// ---------------------
// ------------INTRO_PAGE
// ---------------------

export const SPECIAL_DATA = [
  {
    id: 1,
    text: (
      <>
        마약사건 수사·재판 경험을 가진
        <br />
        <strong>검사출신 변호사</strong>들로 구성
      </>
    ),
    subText: (
      <>
        마약 범죄는 변호사의 역량에 따라 결과가 달라질 수 있습니다.
        <br className="mbBr" /> 온강은 마약 사건을 직접 수사 · 재판한 검사출신 변호사들이&nbsp;
        <br className="mbBr" />
        <br className="pcBr" /> 담당 변호사와 함께 원팀(one-team)이 되어 사건을 주관하여, 흐름을 꿰뚫는 통찰력으로 선제적인 대응을 통해&nbsp;
        <br className="mbBr" />
        <br className="pcBr" /> 의뢰인이 원하는 결과를 얻을 수 있도록 최선을 다합니다.
      </>
    ),
    imgList: [
      { id: 1, src: IMG.ROYAL_01, alt: "royal img, 배한진 변호사 이미지", title: "검사출신" },
      { id: 2, src: IMG.ROYAL_02, alt: "royal img, 성인욱 변호사 이미지", title: "검사출신" },
      { id: 3, src: IMG.ROYAL_03, alt: "royal img, 심강현 변호사 이미지", title: "검사출신" },
      { id: 4, src: IMG.ROYAL_04, alt: "royal img, 이고른 변호사 이미지", title: "검사출신" },
      { id: 5, src: IMG.ROYAL_05, alt: "royal img,  변호사 이미지", title: "검사출신" },
    ],
  },
  {
    id: 2,
    text: (
      <>
        마약을 주제로 대중강연을&nbsp;
        <br className="mbBr" />
        진행, 마약사건 관련 언론 출연 등&nbsp;
        <br className="pcBr" />
        마약사건에 집중하는 로펌
      </>
    ),
    subText: (
      <>
        마약은 특히나 전문성이 요구되는 분야입니다. 온강의&nbsp;
        <br className="mbBr" />
        변호인단은&nbsp;&lt;어쩌다 어른&gt;, &lt;경기언론인클럽&gt;, &lt;아침마당&gt;&nbsp;
        <br className="mbBr" />
        등&nbsp;
        <br className="pcBr" />
        마약을 주제로 한 대중강연을 진행하여 전문성을&nbsp;
        <br className="mbBr" />
        입증했습니다.
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        재판에서 끝나지 않고 치료 재활 연계를
        <br className="pcBr" />
        통해 의뢰인들의&nbsp;
        <br className="mbBr" />
        <strong>미래를 생각</strong>하는 로펌
      </>
    ),
    subText: (
      <>
        법무법인 온강은 진심 정신 건강의학과 중독 치료 전문의와 MOU 체결하여
        <br className="pcBr" />
        의뢰인이 진심으로 사회에 진출할 수 있도록&nbsp;
        <br className="mbBr" />
        돕습니다.
      </>
    ),
  },

  {
    id: 4,
    text: (
      <>
        <strong>마약 재활단체</strong> 정기적 기부 활동을
        <br className="pcBr" />
        <br className="mbBr" />
        지속적으로 진행하는 로펌
      </>
    ),
    subText: (
      <>
        법무법인 온강은 남성 약물중독자들을 대상으로 상담, 재활 및 교육 프로그램,
        <br className="pcBr" />
        사회 적응 훈련, 가족 지원 프로그램 등을 운영하는 마약 재활 단체 다르크에
        <br className="pcBr" />
        정기적으로 기부 활동을&nbsp;
        <br className="mbBr" />
        진행하고 있습니다.
      </>
    ),
  },

  {
    id: 5,
    text: (
      <>
        <strong>사전 마약검사</strong>를 통해&nbsp;
        <br />
        <strong>마약사건</strong>을 철저하게 준비하는 로펌
      </>
    ),
    subText: (
      <>
        법무법인 온강은 자체 소변 간이시약 검사 및 모발검사가 가능합니다.
        <br className="pcBr" />
        이를 통해 법의 테두리 안에서 피의자가 방어권을 보장받을 수 있도록 조력합니다.
      </>
    ),
  },
];

export const EXPERT_DATA = [
  { id: 1, linkTo: "/intro/expert/detail/1", title: "배한진", subTitle: "검사출신", src: IMG.ROYAL_DETAIL_EX },
  { id: 2, linkTo: "/intro/expert/detail/2", title: "배한진", subTitle: "검사출신", src: IMG.ROYAL_DETAIL_EX },
];

export const RESUME_DATA = [
  {
    category: "前",
    list: [
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
    ],
  },
  {
    category: "現",
    list: [
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            인천지방검찰청 마약 특수부 검사
            <br />(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
      {
        text: (
          <>
            대전지방검찰청 천안지청 검사(<strong>마약</strong>, 조직범죄, 공판)
          </>
        ),
      },
    ],
  },
];

export const INTRO_HEADING_DATA = {
  URL: "intro",
  HEAD: {
    subject: "온강 마약센터",
    title: {
      introduce: "온강 소개",
      special: "온강의 특별함",
      expert: "전문가 소개",
      news: "언론보도",
      map: "오시는 길",
    },
    subTitle: {
      introduce: "",
      special: (
        <>
          온강은 로펌의 규모가 아닌 전문성의 깊이로 성장하는&nbsp;
          <br className="mbBr" />
          로펌입니다.&nbsp;
          <br className="pcBr" />
          의뢰인 편에 선 법률 동반자가 되어&nbsp;
          <br className="mbBr" />
          따뜻하지만 강한 변호로&nbsp;
          <br className="pcBr" />
          의뢰인의 성원에 보답하겠습니다.
        </>
      ),
      expert: (
        <>
          온강은 의뢰인의 만족을 최우선으로 하며,
          <br className="mbBr" />
          <br className="pcBr" />
          검사출신 변호사들이 진심을 다해 당신의&nbsp;
          <br className="mbBr" />
          편에서 싸웁니다.
        </>
      ),
      news: (
        <>
          온강의 진심과 열정이 세상에 닿아,
          <br className="mbBr" />
          <br className="pcBr" />더 많은 사람들에게 도움이 되길 바랍니다.
        </>
      ),
      map: (
        <>
          의뢰인이 있는 한 온강의 불은 꺼지지 않습니다.
          <br className="mbBr" />
          <br className="pcBr" />
          온강에 오시는 길이 희망으로 가는 길이 되길
          <br className="mbBr" />
          바랍니다.
        </>
      ),
    },
  },
  TAB_LIST: [
    {
      id: 1,
      label: "온강소개",
      tabLink: "introduce",
    },
    {
      id: 2,
      label: "온강의 특별함",
      tabLink: "special",
    },
    {
      id: 3,
      label: "전문가 소개",
      tabLink: "expert",
    },
    {
      id: 4,
      label: "언론보도",
      tabLink: "news",
    },
    {
      id: 5,
      label: "오시는 길",
      tabLink: "map",
    },
  ],
};

export const NEWS_LIST = [
  { linkTo: "/intro/expert/detail/1", title: "배한진", subTitle: "검사출신", src: IMG.ROYAL_EX },
  { linkTo: "/intro/expert/detail/2", title: "배한진", subTitle: "검사출신", src: IMG.ROYAL_EX },
];

export const EX_SWIPER_LIST = [
  {
    linkTo: "/intro/news/detail/1",
    type: "글로벌에픽",
    img: IMG.FOCUS_05,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle:
      "새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/intro/news/detail/2",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의",
    subTitle: "새겨지는 별이 그리고 것은 이름과 까닭이요",
  },
  {
    linkTo: "/intro/news/detail/3",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/intro/news/detail/3",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
];

export const NEWS_COLUMN_LIST = [
  {
    linkTo: "/intro/news/detail/4",
    type: "컬럼",
    src: IMG.FOCUS_02,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    linkTo: "/intro/news/detail/5",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    linkTo: "/intro/news/detail/6",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    linkTo: "/intro/news/detail/7",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    linkTo: "/intro/news/detail/8",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    linkTo: "/intro/news/detail/9",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
];

// ---------------------
// Start:: -------------
// ---------------------
// ---------- STORY_PAGE
// ---------------------
export const STORY_HEADING_DATA = {
  URL: "story",
  HEAD: {
    subject: "온강 이야기",
    title: {
      review: "의뢰인 후기",
      youtube: "유튜브",
      column: "컬럼",
    },
    subTitle: {
      review: (
        <>
          누군가에게 희망이 되고, 또 함께 기뻐할 수 있어&nbsp;
          <br className="mbBr" />
          행복합니다.
          <br className="pcBr" />
          여러분의 감사 인사가 온강의&nbsp;
          <br className="mbBr" />
          보람이자 원동력입니다.
        </>
      ),
      youtube: (
        <>
          마약 전담 검사출신 변호사들이 알려주는&nbsp;
          <br className="mbBr" />
          ‘생생한 형사 이야기’,
          <br className="pcBr" />
          영상으로 확인하세요.
        </>
      ),
      column: (
        <>
          누구나 할 수 있는 사전적인 이야기는 하지&nbsp;
          <br className="mbBr" />
          않겠습니다.&nbsp;
          <br className="pcBr" />
          두렵고 답답한 마음을 해결할 수&nbsp;
          <br className="mbBr" />
          있는 실전 노하우만 공개합니다.
        </>
      ),
    },
  },
  TAB_LIST: [
    {
      id: 1,
      label: "의뢰인 후기",
      tabLink: "review",
    },
    {
      id: 2,
      label: "유튜브",
      tabLink: "youtube",
    },
    {
      id: 3,
      label: "컬럼",
      tabLink: "column",
    },
  ],
};

export const STORY_SWIPER_LIST = [
  {
    linkTo: "/story/review/detail/1",
    type: "글로벌에픽",
    hoverImg: IMG.FOCUS_01,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle:
      "새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/story/review/detail/2",
    type: "글로벌에픽",
    hoverImg: IMG.FOCUS_05,
    title: "사람들의",
    subTitle: "새겨지는 별이 그리고 것은 이름과 까닭이요",
  },
  {
    linkTo: "/story/review/detail/3",
    type: "글로벌에픽",
    hoverImg: IMG.STROY_SW_HOVER_IMG,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/story/review/detail/3",
    type: "글로벌에픽",
    hoverImg: IMG.STROY_SW_HOVER_IMG,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
];

export const STORY_COLUMN_LIST = [
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/9",
    type: "컬럼",
    src: IMG.FOCUS_01,
    title: "제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다",
    subTitle: "의뢰인은의 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/1",
    type: "컬럼",
    src: IMG.FOCUS_06,
    title: "제목입니다",
    subTitle:
      "의뢰인은의써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/2",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle: "의뢰인은의뢰인은의뢰인은의뢰인 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/3",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/4",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/5",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/6",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/7",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/review/detail/8",
    type: "컬럼",
    src: IMG.STORY_SW_EX_IMG,
    title: "제목입니다",
    subTitle: "의뢰인은뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
];

export const STORY_CATEGORY_SWIPER_LIST = [
  {
    linkTo: "/story/youtube",
    src: IMG.FOCUS_03,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
  },
  {
    linkTo: "/story/youtube",
    src: IMG.STORY_SW_EX_IMG,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
  },
  {
    linkTo: "/story/youtube",
    src: IMG.STORY_SW_EX_IMG,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
  },
  {
    linkTo: "/story/youtube",
    src: IMG.STORY_SW_EX_IMG,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
  },
];

export const STORY_EX_SWIPER_LIST = [
  {
    linkTo: "/story/column/detail/1",
    type: "글로벌에픽",
    img: IMG.PROCESS_04,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle:
      "새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요새겨지는 별이 그리고 것은 없이 있습니다. 했던 위에 아름다운 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/story/column/detail/2",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의",
    subTitle: "새겨지는 별이 그리고 것은 이름과 까닭이요",
  },
  {
    linkTo: "/story/column/detail/3",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
  {
    linkTo: "/story/column/detail/3",
    type: "글로벌에픽",
    img: IMG.ExImg,
    title: "사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의사람들의",
    subTitle: " 덮어 밤을 그러나 이름과 까닭이요",
  },
];

export const STORY_COLUMN_LIST_02 = [
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/9",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다제목입니다",
    subTitle: "의뢰인은의 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/1",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/2",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle: "의뢰인은의뢰인은의뢰인은의뢰인 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/3",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/4",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/5",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/6",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/7",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle:
      "의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯이, 계십니다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
  {
    date: "2024.01.01",
    linkTo: "/story/column/detail/8",
    type: "컬럼",
    src: IMG.ExImg,
    title: "제목입니다",
    subTitle: "의뢰인은뢰인은의뢰인은 써 하나에 그리고 동경과 가을로 다. 위에 이네들은 가득 까닭입니다. 의뢰인은 써 하나에 그리고 동경과 가을로 멀듯 ",
  },
];

// ---------------------
// Start:: -------------
// ---------------------
// ------ SITUATION_PAGE
// ---------------------
export const SITUATION_HEADING_DATA = {
  URL: "situation",
  HEAD: {
    subject: "상황별 대응 전략",
    title: {
      medication: "마약류 단순 구매 투약(흡연) 사건\n수사과정 및 대응방법",
      sales: "마약류 판매 유통사건\n수사과정 및 대응방법",
      smuggling: "마약류 밀수 제조사건\n수사과정 및 대응방법",
      arrest: "체포구속 된 경우\n수사과정 및 대응방법",
    },
    subTitle: {
      medication: "",
      sales: "",
      smuggling: "",
      arrest: "",
    },
  },
  TAB_LIST: [
    {
      id: 1,
      label: "구매 투약",
      tabLink: "medication",
    },
    {
      id: 2,
      label: "판매유통",
      tabLink: "sales",
    },
    {
      id: 3,
      label: "밀수제조",
      tabLink: "smuggling",
    },
    {
      id: 3,
      label: "체포구속상태",
      tabLink: "arrest",
    },
  ],
};
export const SITUATION_APPLY = {
  medication: {
    title: (
      <>
        <strong>초범</strong>이면 불구속 상태에서
        <br />
        조사받고 <strong>실형 확률</strong>도 낮을까요?
      </>
    ),
    subTitle: [{ text: "절대 아닙니다." }, { text: "초범일지라도 아래 상황에 따라 실형 선고도 가능합니다." }],
    step: [{ text: "투약 일시와 횟수" }, { text: "소변, 모발 검사" }, { text: "구매 상선 및 공범 여부" }],
    src: IMG.SITUATION_TAB_MEDICATION,
    mbSrc: IMG.MB_SITUATION_TAB_MEDICATION,
  },
  sales: {
    title: (
      <>
        판매 및 유통 사범은 초범이라도
        <br className="mbBr" />
        <br className="pcBr" />
        <strong>강제수사(체포, 압수수색 등)</strong>를<br className="pcBr" />
        <br className="mbBr" />
        통한 <strong>구속 확률이 높다</strong>는<br className="mbBr" />
        <br className="pcBr" />
        사실은 알고 계시죠?
      </>
    ),
    subTitle: [{ text: "경찰 첫 조사진술로 구속 및 실형 가능성이" }, { text: "달라질 수 있습니다." }],
    step: [{ text: "자백, 부인 여부" }, { text: "유통량과 유통 횟수(기간)" }, { text: "구매 상선 및 공범 여부" }],
    src: IMG.SITUATION_TAB_SALES,
    mbSrc: IMG.MB_SITUATION_TAB_SALES,
  },
  smuggling: {
    title: (
      <>
        미국 등 외국에서는 마약밀수, 제조는
        <br className="pcBr" />
        대부분 <strong>종신형</strong>이며 국내에서는&nbsp;
        <br className="pcBr" />
        최소&nbsp;
        <br className="mbBr" />
        <strong>구형이 5~10년</strong>입니다.
      </>
    ),
    subTitle: [{ text: "치밀한 대응책 없이는" }, { text: "일상으로 돌아오지 못할 가능성이 큽니다." }],
    step: [{ text: "밀수, 제조를 업으로 했는지 여부" }, { text: "조직적으로 했는지 여부" }, { text: "단순 심부름책, 공범 인지 여부" }, { text: "밀수, 제조량과 횟수 및 기간" }],
    src: IMG.SITUATION_TAB_SMUGGLING,
    mbSrc: IMG.MB_SITUATION_TAB_SMUGGLING,
  },
  arrest: {
    title: (
      <>
        갑작스러운 <strong>구속</strong>,
        <br />
        <strong>압수수색 통지</strong>에 놀라셨나요?
      </>
    ),
    subTitle: [{ text: "내 가족, 지인이 이미 구속되었다면" }, { text: "지금부터 여러분의 역할이 중요합니다." }],
    step: [
      {
        text: (
          <>
            구속 2일 내 ‘구속영장실질심사’에 대응하지 못하면
            <br className="pcBr" />
            꼼짝없이 구속될 수 있습니다.
          </>
        ),
      },
      {
        text: (
          <>
            구속기간은 경찰 10일, 검찰 20일이기에
            <br className="pcBr" />이 기간 내에 신속한 변호가 필수적입니다.
          </>
        ),
      },
      {
        text: (
          <>
            피의자가 다시 범죄를 저지르지 않도록&nbsp;
            <br className="mbBr" />
            치료 등 가족,
            <br className="pcBr" />
            지인이 적극적으로&nbsp;
            <br className="mbBr" />
            도와주셔야 감형에 도움이 됩니다.
          </>
        ),
      },
    ],
    src: IMG.SITUATION_TAB_ARREST,
    mbSrc: IMG.MB_SITUATION_TAB_ARREST,
  },
};

export const SITUATION_FOCUS = {
  medication: {
    title: (
      <>
        혹시 <strong>이러한 상황</strong>에 처해있나요?
        <br />
        <span className="light">예를 들어,</span>
      </>
    ),
    imgList: [
      {
        src: IMG.FOCUS_01,
        text: (
          <>
            소변 및 모발 감정이 양성으로 나온 경우,
            <br className="pcBr" />
            <br className="mbBr" />
            모두 인정하고 솔직하게 얘기하면 선처받을&nbsp;수&nbsp;
            <br className="mbBr" />
            있을까요?
          </>
        ),
      },
      {
        src: IMG.FOCUS_02,
        text: (
          <>
            반대로 실제 투약을 했고 자백도 했지만 음성으로 나온 경우,
            <br className="pcBr" />
            진술을 번복하는게 맞을까요?
          </>
        ),
      },
    ],
    textBanner: "단계별 감형 전략",
  },
  sales: {
    title: (
      <>
        혹시 <strong>이러한 상황</strong>에 처해있나요?
        <br />
        <span className="light">예를 들어,</span>
      </>
    ),
    imgList: [
      {
        src: IMG.FOCUS_03,
        text: (
          <>
            경찰이 어떤 증거를 가지고 있는지 모르는&nbsp;
            <br className="mbBr" />
            상황에서
            <br className="pcBr" />
            자백/부인 어떤 방향으로 진술하는 게&nbsp;
            <br className="mbBr" />
            맞을까요?
          </>
        ),
      },
      {
        src: IMG.FOCUS_04,
        text: (
          <>
            구매자, 상선 중 누구의 제보로 잡힌 것인지
            <br className="mbBr" />
            &nbsp;알지 못하는
            <br className="pcBr" />
            상태에서 제보자와 진술이 엇갈리면
            <br className="mbBr" />
            &nbsp;어떻게될까?
          </>
        ),
      },
    ],
    textBanner: "단계별 감형 전략",
  },
  smuggling: {
    title: (
      <>
        혹시 <strong>이러한 상황</strong>에 처해있나요?
        <br />
        <span className="light">예를 들어,</span>
      </>
    ),
    imgList: [
      {
        src: IMG.FOCUS_05,
        text: (
          <>
            조직 내 누구의 자백으로 걸린 것인지,
            <br />또 어떻게 진술했을 지 알고 있나요?
          </>
        ),
      },
      {
        src: IMG.FOCUS_06,
        text: (
          <>
            유통책과 동료 및 동업자들과는
            <br />
            어떤 방향으로 진술해야 선처받을 수 있을까요?
          </>
        ),
      },
    ],
    textBanner: "단계별 감형 전략",
  },
  arrest: {
    title: (
      <>
        마약은 범죄이기 전에 <strong>치료받아야 하는 중독질환</strong>입니다
      </>
    ),

    imgList: [
      {
        src: IMG.FOCUS_07,
        text: (
          <>
            온강에서는 선처를 받는 동시에 마약의 늪에서
            <br className="mbBr" />
            &nbsp;빠져나올 수 있도록
            <br className="pcBr" />
            마약치료센터에 연계하여
            <br className="mbBr" />
            &nbsp;일상회복을 돕고 있습니다.
          </>
        ),
      },
    ],
    textBanner: "단계별 감형 전략",
  },
};

export const SITUATION_PROCESS = {
  medication: {
    popData: [
      {
        item: {
          list: [
            {
              title: <>1. 적발</>,
              subTitle: [{ text: <>인지(기획)수사로 적발</> }, { text: <>공범의 제보로 적발</> }, { text: <>별건 구매내역, 리스트로 적발</> }],
            },
            {
              title: <>2. 출석요구</>,
              subTitle: [
                {
                  text: (
                    <>
                      현장 체포 외에는 원칙적으로
                      <br />
                      유선으로 출석 일정 조율
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 경찰출석 (1회 이상)</>,
              subTitle: [
                { text: <>소변, 모발 검사 실시</>, innerList: [{ text: <>간이시약검사(아큐사인) : 즉시 결과 확인</> }, { text: <>국과수 : 약 2~3주 후 결과 확인</> }] },
                {
                  text: (
                    <>
                      추가 조사 실시 &#8594; 최초 진술 방향, 모발 검사 결과나
                      <br />
                      공범 진술 등 다른 증거에 따라 추가 조사 여부 결정
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-1. 검찰 송치 결정</>,
              subTitle: [
                {
                  text: (
                    <>
                      구속상태 : 재범가능성, 도망의 염려 등<br />
                      구속사유 충족 시 구속영장 발부되어 구속 상태로 검찰 송치
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      불구속 상태 : 초범 등 재범가능성 낮은 경우
                      <br />
                      불구속 상태로 검찰 송치
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-2. 불송치 결정(무혐의)</>,
              subTitle: [
                {
                  text: (
                    <>
                      소변 및 모발 검사 결과 음성 : 투약사건에 범행 부인하고
                      <br />
                      감정결과 음성인 경우 불송치 가능성 높음
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      범죄 혐의를 입증할 증거가 부족 : 유통사건에서 공범의
                      <br />
                      진술을 믿기 어려운 경우 불송치 가능성 높음
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 검찰 추가 조사</>,
              subTitle: [
                {
                  text: <>범죄 혐의 부인하는 경우</>,
                },
                {
                  text: (
                    <>
                      마약검사 음성반응 등 증거부족으로 피의자 자백진술이
                      <br />
                      반드시 필요한 경우
                    </>
                  ),
                },
                {
                  text: <>추가 여죄가 발견된 경우</>,
                },
              ],
            },

            {
              title: <>2-1. 기소 처분</>,
              subTitle: [
                {
                  text: <>불구속 기소 : 재범가능성 적고, 구속사유가 없는 경우</>,
                },
                {
                  text: (
                    <>
                      구속 기소 : 투약 횟수 과다, 재범자 등<br />
                      구속사유 충분한 경우 검찰에서 직접
                      <br />
                      구속영장 청구하여 구속
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-2. 불기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      혐의없음 : 피의자가 혐의 부인하고, 혐의를
                      <br />
                      증명할 증거가 불충분한 경우 혐의없음 처분함
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      기소유예 : 피의자가 혐의 인정 및 반성하며, 죄질이
                      <br />
                      비교적 무겁지 않을 때 선처의 의미로 기소를 유예함
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 공판 단계(2회 이상)</>,
              subTitle: [
                { text: <>검사 : 공소사실을 입증하는 역할</> },
                { text: <>피의자 &#8594; 피고인</>, innerList: [{ text: <>인정 : 감형 전략으로 변호(마약사건 법리검토는 필수적으로 진행)</> }, { text: <>부인 : 무죄 전략으로 변호</> }] },
              ],
            },

            {
              title: <>2. 구형 및 최후변론</>,
              subTitle: [{ text: <>검사 : 재판부에 피고인에 대한 구형</> }, { text: <>피고인 : 선처/무죄를 위한 최후 변론</> }],
            },
            {
              title: <>3. 판결 선고 및 항소</>,
              subTitle: [
                { text: <>실형이 선고되는 경우 즉시 법정 구속</> },
                {
                  text: (
                    <>
                      판결에 불복하는 경우 상급법원에 선고 결과에 대해 항소 진행
                      <br />
                      (7일 이내)
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },
    ],
  },
  sales: {
    popData: [
      {
        item: {
          list: [
            {
              title: <>1. 적발</>,
              subTitle: [{ text: <>인지(기획)수사로 적발</> }, { text: <>구매자, 상선의 제보로 적발</> }, { text: <>거래 내역 및 리스트로 적발</> }],
            },
            {
              title: <>2-1. 출석요구</>,
              subTitle: [
                {
                  text: <>유선으로 출석 일정 조율</>,
                },
              ],
            },

            {
              title: <>2-2. 강제수사</>,
              subTitle: [
                {
                  text: <>체포, 통신, 압수수색영장을 통한 수사개시</>,
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1-1. 출석 조사(임의수사)</>,
              subTitle: [
                { text: <>소변, 모발 검사 실시</>, innerList: [{ text: <>간이시약검사(아큐사인) : 즉시 결과 확인</> }, { text: <>국과수 : 약 2~3주 후 결과 확인</> }] },
                {
                  text: (
                    <>
                      피의자 조사 &#8594; 최초 진술 방향, 모발 검사 결과나
                      <br />
                      공범 진술 등 다른 증거에 따라 추가 조사 여부 결정
                    </>
                  ),
                },
              ],
            },

            {
              title: <>1-2. 체포 조사(강제수사)</>,
              subTitle: [
                {
                  text: <>소변, 모발 검사 실시</>,
                },
                {
                  text: <>체포된 경우 검사에게 48시간 이내 구속 영장 신청</>,
                  innerList: [
                    {
                      text: (
                        <>
                          범죄 혐의가 강하게 의심되며, 증거인멸 및 도망의
                          <br />
                          우려 등 구속사유 충족 시
                        </>
                      ),
                    },
                  ],
                },
              ],
            },

            {
              title: <>2-1. 석방(불구속 수사)</>,
              subTitle: [
                {
                  text: <>제보자의 진술을 신뢰할 수 없는 경우</>,
                },
                {
                  text: <>범죄 혐의를 입증할 증거가 부족한 경우</>,
                },
                {
                  text: <>증거인멸, 도망 우려 등 구속사유 없는 경우</>,
                },
              ],
            },

            {
              title: <>2-2. 검찰 송치 결정</>,
              subTitle: [
                {
                  text: <>구속 상태 송치</>,
                  innerList: [
                    {
                      text: <>체포일로부터 10일 내 구속 송치</>,
                    },
                  ],
                },
                {
                  text: <>불구속 상태 송치</>,
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 검찰 추가 조사</>,
              subTitle: [
                {
                  text: <>범죄 혐의 부인하는 경우</>,
                },
                {
                  text: <>제보자의 허위진술이 의심되는 경우</>,
                },
                {
                  text: <>증거부족으로 피의자 자백진술이 반드시 필요한 경우</>,
                },
                {
                  text: <>추가 여죄가 발견된 경우</>,
                },
              ],
            },

            {
              title: <>2-1. 기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      불구속 기소 : 일회성에 그친 경우나 증거인멸,
                      <br />
                      도망의 우려 등 구속사유가 없는 경우
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      구속 기소 : 상습적이거나 구속사유에 충족되는 경우
                      <br />
                      검찰에서 직접 구속영장 청구하여 구속
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-2. 불기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      혐의없음 : 피의자가 혐의 부인하고, 혐의를 <br />
                      증명할 증거가 불충분한 경우 혐의없음 처분함
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      기소유예 : 피의자가 혐의 인정 및 반성하며, 죄질이
                      <br />
                      비교적 무겁지 않을 때 선처의 의미로 기소를 유예함
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 공판 단계(1회 이상)</>,
              subTitle: [
                { text: <>검사 : 공소사실을 입증하는 역할</> },
                { text: <>피의자 &#8594; 피고인</>, innerList: [{ text: <>인정 : 감형 전략으로 변호(마약사건 법리검토는 필수적으로 진행)</> }, { text: <>부인 : 무죄 전략으로 변호</> }] },
              ],
            },

            {
              title: <>2. 구형 및 최후변론</>,
              subTitle: [{ text: <>검사 : 재판부에 피고인에 대한 구형</> }, { text: <>피고인 : 선처/무죄를 위한 최후 변론</> }],
            },
            {
              title: <>3. 판결 선고 및 항소</>,
              subTitle: [
                { text: <>실형이 선고되는 경우 즉시 법정 구속</> },
                {
                  text: (
                    <>
                      판결에 불복하는 경우 상급법원에 선고 결과에 대해 항소 진행
                      <br />
                      (7일 이내)
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },
    ],
  },
  smuggling: {
    popData: [
      {
        item: {
          list: [
            {
              title: <>1. 적발</>,
              subTitle: [{ text: <>인지(기획)수사로 적발</> }, { text: <>세관 통관 시 적발(압수물 성분 감정 실시)</> }, { text: <>통제 배달, 유통책 검거로 적발</> }],
            },
            {
              title: <>2-1. 출석요구</>,
              subTitle: [{ text: <>유선으로 출석 일정 조율</> }],
            },
            {
              title: <>2-2. 강제수사</>,
              subTitle: [{ text: <>체포, 통신, 압수수색영장을 통한 수사개시</> }],
            },
          ],
        },
      },

      {
        item: {
          important: (
            <>
              밀수 사건의 경우, 수입한 마약류의 시가가 500만 원 이하인 경우 세관에서 수사를 하고,
              <br />
              500만 원 이상인 경우 검찰에서 직접 수사 개시
            </>
          ),
          list: [
            {
              title: <>1. 출석 조사(임의수사)</>,
              subTitle: [
                { text: <>소변, 모발 검사 실시</>, innerList: [{ text: <>간이시약검사(아큐사인) : 즉시 결과 확인</> }, { text: <>국과수 : 약 2~3주 후 결과 확인</> }] },
                {
                  text: (
                    <>
                      피의자 조사 &#8594; 최초 진술 방향, 모발검사 결과,
                      <br />
                      공범 진술 등 증거 확보 상황에 따라 추가 조사 여부 결정
                    </>
                  ),
                },
              ],
            },

            {
              title: <>1-1. 체포 조사(강제수사)</>,
              subTitle: [
                { text: <>소변, 모발 검사 실시</> },
                {
                  text: <>체포된 경우 검사에게 48시간 이내 구속 영장 신청</>,
                  innerList: [
                    {
                      text: (
                        <>
                          범죄 혐의가 강하게 의심되며, 증거인멸 및<br /> 도망의 우려 등 구속사유 충족 시
                        </>
                      ),
                    },
                  ],
                },
                {
                  text: (
                    <>
                      밀수, 재배사범은 구속수사가 원칙이므로 체포,
                      <br />
                      구속될 가능성이 매우 높음
                    </>
                  ),
                  em: true,
                },
              ],
            },

            {
              title: <>2-1. 석방(불구속 수사)</>,
              subTitle: [{ text: <>제보자의 진술을 신뢰할 수 없는 경우</> }, { text: <>범죄 혐의를 입증할 증거가 부족한 경우</> }, { text: <>증거인멸, 도망 우려 등 구속사유 없는 경우</> }],
            },

            {
              title: <>2-2. 검찰 송치 결정</>,
              subTitle: [
                {
                  text: <>구속 상태 송치</>,
                  innerList: [
                    {
                      text: <>체포일로부터 10일 내 구속 송치</>,
                    },
                  ],
                },
                {
                  text: <>불구속 상태 송치</>,
                },
              ],
            },
          ],
        },
      },
      {
        item: {
          important: <>검찰 직접수사의 경우, 세관 수사 과정과 동일</>,
          list: [
            {
              title: (
                <>
                  1. (세관 송치사건)
                  <br /> 검찰 추가 조사
                </>
              ),
              subTitle: [
                { text: <>범죄 혐의 부인하는 경우</> },
                { text: <>제보자의 허위진술이 의심되는 경우</> },
                { text: <>증거부족으로 피의자 자백진술이 반드시 필요한 경우</> },
                { text: <>추가 여죄가 발견된 경우</> },
              ],
            },

            {
              title: <> 2-1. 기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      불구속 기소 : 일회성에 그친 경우나 증거인멸,
                      <br /> 도망의 우려 등 구속사유가 없는 경우
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      구속 기소 : 상습적이거나 구속사유에 충족되는 경우
                      <br /> 검찰에서 직접 구속영장 청구하여 구속
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-2. 불기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      혐의없음 : 피의자가 혐의 부인하고, 혐의를
                      <br />
                      증명할 증거가 불충분한 경우 혐의없음 처분함
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      기소유예 : 피의자가 혐의 인정 및 반성하며, 죄질이
                      <br />
                      비교적 무겁지 않을 때 선처의 의미로 기소를 유예함
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 공판 단계(1회 이상)</>,
              subTitle: [
                { text: <>검사 : 공소사실을 입증하는 역할</> },
                { text: <>피의자 &#8594; 피고인</>, innerList: [{ text: <>인정 : 감형 전략으로 변호(마약사건 법리검토는 필수적으로 진행)</> }, { text: <>부인 : 무죄 전략으로 변호</> }] },
              ],
            },

            {
              title: <>2. 구형 및 최후변론</>,
              subTitle: [{ text: <>검사 : 재판부에 피고인에 대한 구형</> }, { text: <>피고인 : 선처/무죄를 위한 최후 변론</> }],
            },
            {
              title: <>3. 판결 선고 및 항소</>,
              subTitle: [
                { text: <>실형이 선고되는 경우 즉시 법정 구속</> },
                {
                  text: (
                    <>
                      판결에 불복하는 경우 상급법원에 선고 결과에 대해 항소 진행
                      <br />
                      (7일 이내)
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },
    ],
  },
  arrest: {
    popData: [
      {
        item: {
          list: [
            {
              title: <>1. 적발</>,
              subTitle: [
                { text: <>인지(기획)수사로 적발</> },
                { text: <>구매자, 상선의 제보로 적발</> },
                { text: <>거래 내역 및 리스트로 적발</> },
                { text: <>세관 통관 시 적발</> },
                { text: <>통제 배달, 유통책 검거로 적발</> },
              ],
            },
            {
              title: <>2. 출석요구</>,
              subTitle: [
                { text: <>불구속 상태의 피의자가 직접 적발 사실을 가족에게 알림</> },
                { text: <>체포(구속) 시 체포(구속) 사실이 가족에게 통지됨</> },
                { text: <>자택 압수수색 등 강제수사로 알게 됨</> },
                { text: <>피의자가 마약류에 중독된 사실을 직접 경험하여 알게 됨</> },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 조사 후 구속 여부 결정</>,
              subTitle: [
                { text: <>소변, 모발 검사 실시</> },
                {
                  text: <>체포된 경우 검사에게 48시간 이내 구속 영장 신청</>,
                  innerList: [
                    {
                      text: (
                        <>
                          범죄 혐의가 강하게 의심되며, 증거인멸 및<br />
                          도망의 우려 등 구속사유 충족 시
                        </>
                      ),
                    },
                  ],
                },

                {
                  text: <>불구속 수사</>,
                  innerList: [
                    {
                      text: <>구속영장실질심사 방어에 성공하는 경우</>,
                    },
                    {
                      text: <>구속적부심을 신청해 성공하는 경우</>,
                    },
                  ],
                },
              ],
            },

            {
              title: <>2-1. 검찰 송치 결정</>,
              subTitle: [{ text: <>구속 상태 송치</> }, { text: <>불구속 상태 송치</> }],
            },
            {
              title: <>2-2. 불송치 결정(무혐의)</>,
              subTitle: [{ text: <>제보자의 진술을 신뢰할 수 없는 경우</> }, { text: <>범죄 혐의들을 입증할 증거가 부족한 경우</> }],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 검찰 추가 조사</>,
              subTitle: [
                { text: <>범죄 혐의 부인하는 경우</> },
                { text: <>제보자의 허위진술이 의심되는 경우</> },
                { text: <>증거부족으로 피의자 자백진술이 반드시 필요한 경우</> },
                { text: <>추가 여죄가 발견된 경우</> },
              ],
            },

            {
              title: <> 2-1. 기소 처분</>,
              subTitle: [
                {
                  text: <>불구속 기소 : 재범가능성 적고, 구속사유가 없는 경우</>,
                },
                {
                  text: (
                    <>
                      구속 기소 : 투약 횟수 과다, 재범자 등 <br />
                      구속사유 충분한 경우 검찰에서 직접 <br />
                      구속영장 청구하여 구속
                    </>
                  ),
                },
              ],
            },

            {
              title: <>2-2. 불기소 처분</>,
              subTitle: [
                {
                  text: (
                    <>
                      혐의없음 : 피의자가 혐의 부인하고, 혐의를
                      <br />
                      증명할 증거가 불충분한 경우 혐의없음 처분함
                    </>
                  ),
                },
                {
                  text: (
                    <>
                      기소유예 : 피의자가 혐의 인정 및 반성하며, 죄질이
                      <br />
                      비교적 무겁지 않을 때 선처의 의미로 기소를 유예함
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },

      {
        item: {
          list: [
            {
              title: <>1. 공판 단계(1회 이상)</>,
              subTitle: [
                { text: <>검사 : 공소사실을 입증하는 역할</> },
                { text: <>피의자 &#8594; 피고인</>, innerList: [{ text: <>인정 : 감형 전략으로 변호(마약사건 법리검토는 필수적으로 진행)</> }, { text: <>부인 : 무죄 전략으로 변호</> }] },
              ],
            },

            {
              title: <>2. 구형 및 최후변론</>,
              subTitle: [{ text: <>검사 : 재판부에 피고인에 대한 구형</> }, { text: <>피고인 : 선처/무죄를 위한 최후 변론</> }],
            },
            {
              title: <>3. 판결 선고 및 항소</>,
              subTitle: [
                { text: <>실형이 선고되는 경우 즉시 법정 구속</> },
                {
                  text: (
                    <>
                      판결에 불복하는 경우 상급법원에 선고 결과에 대해 항소 진행
                      <br />
                      (7일 이내)
                    </>
                  ),
                },
              ],
            },
          ],
        },
      },
    ],
  },
};

export const SITUATION_DIALOG = {
  medication: {
    hoverData: [
      {
        title: <>계획대로 진술하는 것은 쉽지 않습니다</>,
        countList: [
          {
            title: <>경찰 · 검찰조사 시뮬레이션</>,
            subTitle: [
              {
                text: (
                  <>
                    - 단순한 조사연습이 아닌,
                    <br className="mbBr" /> 의뢰인을 ‘가상의 피의자’로 설정
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 검사출신 변호사가 실제 수사관
                    <br className="mbBr" /> 역할로 시뮬레이션 진행
                  </>
                ),
              },
              { text: <>- 혐의를 인정하는 사건에서는 의뢰인이 잘못한 만큼만 처벌받을 수 있도록 진술방향 안내</> },
              { text: <>- 무죄를 주장하는 사건에서는 억울한 점을 객관적 증거에 맞춰 진술할 수 있도록 진술 코치</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            <strong>첫 경찰 진술</strong>에서 당신이 어떤 처분을 받을지 결정될 수 있습니다.
          </>
        ),
        countList: [
          {
            title: <>마약검사키트 활용</>,
            subTitle: [{ text: <>- 마약검사키트로 마약류에 대한 반응 여부 체크 가능</> }, { text: <>- 검사키트 결과 및 의뢰인 면담을 통해 변론 방향 설정</> }],
          },
          {
            title: <>경찰 조사 연습 및 변호인 의견서 제출</>,
            subTitle: [
              {
                text: (
                  <>
                    - 경찰 조사 시 예상 질문 및 답변에 대한
                    <br className="mbBr" /> 조사 연습 시뮬레이션 실시
                  </>
                ),
              },
              { text: <>- 의뢰인과 함께 유리한 추가 증거 수집</> },
              { text: <>- 분석 내용 및 추가 수집 증거 바탕으로 맞춤형 변호인 의견서 작성 · 제출</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            직접 <strong>마약 사건을 수사하던 검사출신 변호사</strong>가 검찰단계 대응 전략을 제시합니다
          </>
        ),
        countList: [
          {
            title: <>검찰조사 대비</>,
            subTitle: [{ text: <>- 검찰 조사 전 예상질문 도출하고, 그에 맞는 답변내용 준비</> }, { text: <>- 사전 조사 시뮬레이션을 통해 실전처럼 대비</> }],
          },
          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 경찰단계 때 확보하지 못한 증거를 확보하기 위한 수사 진행 방법 촉구</> },
              { text: <>- 구속가능성 높은 사건의 경우, 구속영장이 청구될 가능성을 낮추기 위한 변론</> },
              { text: <>- 선처를 호소하는 사건의 경우, 선처받을 수 있는 양형자료를 담은 의견서 제출</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            재판에서 변곡점을 만들어 낼 수 있도록 <strong>마약 사건을 재판하던 검사 출신 변호사들</strong>이 함께 합니다.
          </>
        ),
        countList: [
          {
            title: <>역추적 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    수사단계를 역으로 추적하여 수사 과정 중에
                    <br />
                    위법성은 없었는지를 철저히 검토
                  </>
                ),
              },
            ],
          },
          {
            title: <>미래 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    의뢰인별 재판 단계의 목표를 수립하고,
                    <br />
                    목표를 달성할 수 있도록 검사출신
                    <br />
                    변호사들이 마약 사건 해결 전략 논의
                  </>
                ),
              },
            ],
          },
          {
            title: <>신문 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    적발 및 수사 과정에서 선처 포인트 어필을 위해
                    <br />
                    중요 증인 및 피고인 신문 진행
                  </>
                ),
              },
            ],
          },
          {
            title: <>선처 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    재판부에서 공적을 확인하고 선처 분위기를
                    <br />
                    조성하기 위해 사실 조회 등의 방법 활용해 지속적 어필
                  </>
                ),
              },
            ],
          },
          {
            title: <>보석 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    보석 신청이 받아들여질 가능성을 논의하고,
                    <br />
                    보석신청을 통해 빠른 석방이 가능한 경우
                    <br />
                    보석을 위해 변론
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서</>,
            subTitle: [
              {
                text: (
                  <>
                    사건기록 전면 검토하여 공판 진행 후 검찰을 탄핵할
                    <br />수 있는 핵심만 정리한 변호인 의견서 제출
                  </>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  sales: {
    hoverData: [
      {
        title: <>계획대로 진술하는 것은 쉽지 않습니다</>,
        countList: [
          {
            title: <>경찰 · 검찰조사 시뮬레이션</>,
            subTitle: [
              {
                text: (
                  <>
                    - 단순한 조사연습이 아닌,
                    <br className="mbBr" /> 의뢰인을 ‘가상의 피의자’로 설정
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 검사출신 변호사가 실제 수사관 <br className="mbBr" />
                    역할로 시뮬레이션 진행
                  </>
                ),
              },
              { text: <>- 혐의를 인정하는 사건에서는 의뢰인이 잘못한 만큼만 처벌받을 수 있도록 진술방향 안내</> },
              { text: <>- 무죄를 주장하는 사건에서는 억울한 점을 객관적 증거에 맞춰 진술할 수 있도록 진술 코치</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            <strong>첫 경찰 진술</strong>에서 당신이 어떤 처분을 받을지 결정될 수 있습니다.
          </>
        ),
        countList: [
          {
            title: <>자수서 제출</>,
            subTitle: [
              { text: <>- 입건 가능성이 높은 경우, 자수서 제출 방법 안내</> },
              {
                text: (
                  <>
                    - 자수서 제출 및 수사협조를 통해 구속영장
                    <br />
                    청구 가능성을 낮추는 방향 제시
                  </>
                ),
              },
            ],
          },
          {
            title: (
              <>
                조사 시뮬레이션
                <br />
                (구속된 피의자 접견)
              </>
            ),
            subTitle: [
              { text: <>- 제보자 특정하여 제보 및 진술 내용 확보</> },
              {
                text: (
                  <>
                    - 의뢰인에게 유리한 증인이나 증거가 있는지
                    <br />
                    확인하고, 추가로 확보 가능한 증거를 확보
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 추가 증거 내용 취합하여 경찰 조사
                    <br />
                    예상질문 및 답변방향 시뮬레이션 실시
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 증인, 증거확보 여부에 따라
                    <br />
                    자백/부인 등 진술 방향 코칭
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 조사 동행 후 수사관의 질문 및 태도 · 수사방향 분석</> },
              { text: <>- 의뢰인에게 유리한 증거 추가 확보 노력</> },
              {
                text: (
                  <>
                    - 분석내용 및 추가 수집 증거 바탕으로
                    <br />
                    맞춤형 변호인 의견서 작성 · 제출
                  </>
                ),
              },
            ],
          },
        ],
      },

      {
        title: (
          <>
            직접 <strong>마약 사건을 수사하던 검사출신 변호사</strong>가 검찰단계 대응 전략을 제시합니다
          </>
        ),
        countList: [
          {
            title: <>검찰조사 대비</>,
            subTitle: [{ text: <>- 검찰 조사 전 예상질문 도출하고, 그에 맞는 답변내용 준비</> }, { text: <>- 사전 조사 시뮬레이션을 통해 실전처럼 대비</> }],
          },

          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 경찰단계 때 확보하지 못한 증거를 확보하기 위한 수사 진행 방법 촉구</> },
              { text: <>- 구속가능성 높은 사건의 경우, 구속영장이 청구될 가능성을 낮추기 위한 변론</> },
              { text: <>- 선처를 호소하는 사건의 경우, 선처받을 수 있는 양형자료를 담은 의견서 제출</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            재판에서 변곡점을 만들어 낼 수 있도록 <strong>마약 사건을 재판하던 검사 출신 변호사들</strong>이 함께 합니다
          </>
        ),
        countList: [
          {
            title: <>역추적 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    수사단계를 역으로 추적하여 수사 과정 중에
                    <br />
                    위법성은 없었는지를 철저히 검토
                  </>
                ),
              },
            ],
          },
          {
            title: <>미래 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    의뢰인별 재판 단계의 목표를 수립하고,
                    <br />
                    목표를 달성할 수 있도록 검사출신
                    <br />
                    변호사들이 마약 사건 해결 전략 논의
                  </>
                ),
              },
            ],
          },
          {
            title: <>신문 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    적발 및 수사 과정에서 선처 포인트 어필을 위해
                    <br />
                    중요 증인 및 피고인 신문 진행
                  </>
                ),
              },
            ],
          },
          {
            title: <>선처 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    재판부에서 공적을 확인하고 선처 분위기를
                    <br />
                    조성하기 위해 사실 조회 등의 방법 활용해 지속적 어필
                  </>
                ),
              },
            ],
          },
          {
            title: <>보석 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    보석 신청이 받아들여질 가능성을 논의하고,
                    <br />
                    보석신청을 통해 빠른 석방이 가능한 경우
                    <br />
                    보석을 위해 변론
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서</>,
            subTitle: [
              {
                text: (
                  <>
                    사건기록 전면 검토하여 공판 진행 후 검찰을 탄핵할
                    <br />수 있는 핵심만 정리한 변호인 의견서 제출
                  </>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  smuggling: {
    hoverData: [
      {
        title: <>계획대로 진술하는 것은 쉽지 않습니다</>,
        countList: [
          {
            title: <>경찰 · 검찰조사 시뮬레이션</>,
            subTitle: [
              {
                text: (
                  <>
                    - 단순한 조사연습이 아닌,
                    <br className="mbBr" /> 의뢰인을 ‘가상의 피의자’로 설정
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 검사출신 변호사가 실제 수사관 <br className="mbBr" />
                    역할로 시뮬레이션 진행
                  </>
                ),
              },
              { text: <>- 혐의를 인정하는 사건에서는 의뢰인이 잘못한 만큼만 처벌받을 수 있도록 진술방향 안내</> },
              { text: <>- 무죄를 주장하는 사건에서는 억울한 점을 객관적 증거에 맞춰 진술할 수 있도록 진술 코치</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            <strong>첫 경찰 진술</strong>에서 당신이 어떤 처분을 받을지 결정될 수 있습니다.
          </>
        ),
        countList: [
          {
            title: <>자수서 제출</>,
            subTitle: [
              { text: <>- 입건 가능성이 높은 경우, 자수서 제출 방법 안내</> },
              {
                text: (
                  <>
                    - 자수서 제출 및 수사협조를 통해 구속영장
                    <br />
                    청구 가능성을 낮추는 방향 제시
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 추가 증거 내용 취합하여 경찰 조사
                    <br />
                    예상질문 및 답변방향 시뮬레이션 실시
                  </>
                ),
              },
            ],
          },

          {
            title: (
              <>
                조사 시뮬레이션
                <br />
                (구속된 피의자 접견)
              </>
            ),
            subTitle: [
              { text: <>- 제보자 특정하여 제보 및 진술내용 확보</> },
              {
                text: (
                  <>
                    - 의뢰인에게 유리한 증인이나 증거가 있는지
                    <br />
                    확인하고, 추가로 확보 가능한 증거를 확보
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 추가 증거 내용 취합하여 경찰 조사
                    <br />
                    예상질문 및 답변방향 시뮬레이션 실시
                  </>
                ),
              },
            ],
          },

          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 조사 동행 후 수사관의 질문 및 태도 · 수사방향 분석</> },
              { text: <>- 의뢰인에게 유리한 증거 추가 확보 노력</> },
              {
                text: (
                  <>
                    - 분석내용 및 추가 수집 증거 바탕으로
                    <br />
                    맞춤형 변호인 의견서 작성 · 제출
                  </>
                ),
              },
            ],
          },
        ],
      },

      {
        title: (
          <>
            직접<strong>마약 사건을 수사하던 검사출신 변호사</strong>가 검찰단계 대응 전략을 제시합니다
          </>
        ),
        countList: [
          {
            title: <>검찰조사 대비</>,
            subTitle: [{ text: <>- 검찰 조사 전 예상질문 도출하고, 그에 맞는 답변내용 준비</> }, { text: <>- 수사 과정 중 위법한 절차가 있는지 검토</> }],
          },
          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 경찰단계 때 확보하지 못한 증거를 확보하기 위한 수사 진행 방법 촉구</> },
              { text: <>- 구속가능성 높은 사건의 경우, 구속영장이 청구될 가능성을 낮추기 위한 변론</> },
              { text: <>- 선처를 호소하는 사건의 경우, 선처받을 수 있는 양형자료를 담은 의견서 제출</> },
            ],
          },
        ],
      },
      {
        title: (
          <>
            재판에서 변곡점을 만들어 낼 수 있도록 <strong>마약 사건을 재판하던 검사 출신 변호사들</strong>이 함께 합니다.
          </>
        ),
        countList: [
          {
            title: <>역추적 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    수사단계를 역으로 추적하여 수사 과정 중에
                    <br />
                    위법성은 없었는지를 철저히 검토
                  </>
                ),
              },
            ],
          },
          {
            title: <>미래 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    의뢰인별 재판 단계의 목표를 수립하고,
                    <br />
                    목표를 달성할 수 있도록 검사출신
                    <br />
                    변호사들이 마약 사건 해결 전략 논의
                  </>
                ),
              },
            ],
          },
          {
            title: <>신문 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    적발 및 수사 과정에서 선처 포인트 어필을 위해
                    <br />
                    중요 증인 및 피고인 신문 진행
                  </>
                ),
              },
            ],
          },
          {
            title: <>선처 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    재판부에서 공적을 확인하고 선처 분위기를
                    <br />
                    조성하기 위해 사실 조회 등의 방법 활용해 지속적 어필
                  </>
                ),
              },
            ],
          },
          {
            title: <>보석 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    보석 신청이 받아들여질 가능성을 논의하고,
                    <br />
                    보석신청을 통해 빠른 석방이 가능한 경우
                    <br />
                    보석을 위해 변론
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서</>,
            subTitle: [
              {
                text: (
                  <>
                    사건기록 전면 검토하여 공판 진행 후 검찰을 탄핵할
                    <br />수 있는 핵심만 정리한 변호인 의견서 제출
                  </>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  arrest: {
    hoverData: [
      {
        title: <>계획대로 진술하는 것은 쉽지 않습니다</>,
        countList: [
          {
            title: <>경찰 · 검찰조사 시뮬레이션</>,
            subTitle: [
              {
                text: (
                  <>
                    - 단순한 조사연습이 아닌,
                    <br className="mbBr" /> 의뢰인을 ‘가상의 피의자’로 설정
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 검사출신 변호사가 실제 수사관 <br className="mbBr" />
                    역할로 시뮬레이션 진행
                  </>
                ),
              },
              { text: <>- 혐의를 인정하는 사건에서는 의뢰인이 잘못한 만큼만 처벌받을 수 있도록 진술방향 안내</> },
              { text: <>- 무죄를 주장하는 사건에서는 억울한 점을 객관적 증거에 맞춰 진술할 수 있도록 진술 코치</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            <strong>첫 경찰 진술</strong>에서 당신이 어떤 처분을 받을지 결정될 수 있습니다.
          </>
        ),
        countList: [
          {
            title:
            <>
              사전초기
              <br />
              구속영장실질심사 / 구속적부심 대비
            </>,
            subTitle: [
              { text: <>- 체포 후 48시간 이내 구속영장실질심사에 대비</> },
              {
                text: (
                  <>
                    - 이미 구속된 상황에서 청구서로 통지 받았다면 석방
                    <br />
                    가능여부 검토
                  </>
                ),
              },
              {
                text: (
                  <>
                    - 피의자 가족, 지인에게 감형에 도움이 되는
                    <br />
                    자료 준비하도록 코칭
                  </>
                ),
              },
            ],
          },
          {
            title: (
              <>
                조사 시뮬레이션
                <br />
                (구속된 피의자 접견)
              </>
            ),
            subTitle: [
              { text: <>- 입건 경위 및 제보자의 진술 내용 확보</> },
              {
                text: (
                  <>
                    - 의뢰인에게 유리한 증인이나 증거가 있는지
                    <br />
                    확인하고, 추가로 확보 가능한 증거를 확보
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 조사 동행 후 수사관의 질문 및 태도 · 수사방향 분석</> },
              { text: <>- 의뢰인에게 유리한 증거 추가 확보 노력</> },
              {
                text: (
                  <>
                    - 분석내용 및 추가 수집 증거 바탕으로
                    <br />
                    맞춤형 변호인 의견서 작성 · 제출
                  </>
                ),
              },
            ],
          },
        ],
      },

      {
        title: (
          <>
            직접 <strong>마약 사건을 수사하던 검사출신 변호사</strong>가 검찰단계 대응 전략을 제시합니다
          </>
        ),
        countList: [
          {
            title: <>검찰조사 대비</>,
            subTitle: [{ text: <>- 검찰 조사 전 예상질문 도출하고, 그에 맞는 답변내용 준비</> }, { text: <>- 사전 조사 시뮬레이션을 통해 실전처럼 대비</> }],
          },

          {
            title: <>변호인 의견서 제출</>,
            subTitle: [
              { text: <>- 경찰단계 때 확보하지 못한 증거를 확보하기 위한 수사 진행 방법 촉구</> },
              { text: <>- 구속가능성 높은 사건의 경우, 구속영장이 청구될 가능성을 낮추기 위한 변론</> },
              { text: <>- 선처를 호소하는 사건의 경우, 선처받을 수 있는 양형자료를 담은 의견서 제출</> },
            ],
          },
        ],
      },

      {
        title: (
          <>
            재판에서 변곡점을 만들어 낼 수 있도록 <strong>마약 사건을 재판하던 검사 출신 변호사들</strong>이 함께 합니다.
          </>
        ),
        countList: [
          {
            title: <>역추적 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    수사단계를 역으로 추적하여 수사 과정 중에
                    <br />
                    위법성은 없었는지를 철저히 검토
                  </>
                ),
              },
            ],
          },
          {
            title: <>미래 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    의뢰인별 재판 단계의 목표를 수립하고,
                    <br />
                    목표를 달성할 수 있도록 검사출신
                    <br />
                    변호사들이 마약 사건 해결 전략 논의
                  </>
                ),
              },
            ],
          },
          {
            title: <>신문 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    적발 및 수사 과정에서 선처 포인트 어필을 위해
                    <br />
                    중요 증인 및 피고인 신문 진행
                  </>
                ),
              },
            ],
          },
          {
            title: <>선처 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    재판부에서 공적을 확인하고 선처 분위기를
                    <br />
                    조성하기 위해 사실 조회 등의 방법 활용해 지속적 어필
                  </>
                ),
              },
            ],
          },
          {
            title: <>보석 전략</>,
            subTitle: [
              {
                text: (
                  <>
                    보석 신청이 받아들여질 가능성을 논의하고,
                    <br />
                    보석신청을 통해 빠른 석방이 가능한 경우
                    <br />
                    보석을 위해 변론
                  </>
                ),
              },
            ],
          },
          {
            title: <>변호인 의견서</>,
            subTitle: [
              {
                text: (
                  <>
                    사건기록 전면 검토하여 공판 진행 후 검찰을 탄핵할
                    <br />수 있는 핵심만 정리한 변호인 의견서 제출
                  </>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
};
