import { useState, useEffect } from "react";
import Styles from './styles';

// img
import IconYoutube from "resources/image/icon/icon_youtube.svg";
import { useSite } from "hooks";

function MainYoutube() {


  const siteInfo = useSite({
    listOption: {
      board_type: 3,
      content_type: 3,
      type: 2,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  return (
    <Styles.MainYoutube>
      <div className="youtube_inner">
        <div className="youtube_title">
          <h2 className="tit">마약 통제배달 수사<br/>미국 DEA 공조수사까지</h2>
          <p className="dec">직접 마약 수사한 생생 마약 수사 경험담</p>
          <div className="youtube">
            <img src={IconYoutube} alt="" />
            <p>지금 <span>영상</span>으로 확인하세요.</p>
          </div>
        </div>
        <ul className="youtube_list">
          <li className="main">
            <iframe 
              src={list && list[2]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </li>
          <li>
            <iframe 
              src={list && list[1]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </li>
          <li>
            <iframe 
              src={list && list[0]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </li>
        </ul>
      </div>
    </Styles.MainYoutube>
  );
}

export default MainYoutube;