import styled, { css } from "styled-components";

const Footer = styled.footer`
  .footer-container {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #c4c4c4;
    margin-top: 150px;
    .footer-inner {
      width: 100%;
      max-width: 1280px;
      padding: 60px 10px;
      .inner-list {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &--item {
          &.left {
            max-width: 652px;
            .footer-logo {
              width: 320px;
              height: 110px;
              margin-bottom: 50px;
            }
            .text-list {
              &--item {
                color: #888888;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                &.heading {
                  font-size: 20px;
                  line-height: 24px;
                  font-weight: 700;
                  margin-bottom: 18px;
                }

                .text {
                  display: inline-block;
                  font-size: 14px;
                  line-height: 17px;
                }
              }
            }
          }

          &.right {
            height: auto;
            .info-list {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              &__item {
                &--heading {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;
                  margin-bottom: 30px;
                  .heading__text {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #111;
                  }
                  .heading__phone {
                    cursor: pointer;
                    font-size: 36px;
                    line-height: 43px;
                    font-weight: 800;
                    color: #da250b;
                    border-bottom: 5px solid #da250b;
                  }
                  .heading__subtext {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 400;
                    color: #888;
                  }
                }
                &--sns {
                  .sns-list {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;
                    &--item {
                      width: 34px;
                      height: 34px;
                    }
                  }
                }
                &--family-site {
                  margin-top: auto;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  /* CustomSelect Style */
  .select-box {
    width: 100%;
    min-width: 310px;
    position: relative;
    z-index: var(--footer-z-index);

    &__inner {
      padding: 13px 16px;
      border: 1px solid #c4c4c4;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #888888;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .img-box {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .select-list {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 100%;
        border: 1px solid #c4c4c4;
        border-bottom: none;
        background: #fff;
        max-height: 180px;
        overflow-y: auto;
      }
      .select-item {
        background: #fff;
        .link {
          width: 100%;
          height: 100%;
          display: inline-block;
          padding: 9px 6px 10px 16px;

          &:hover {
            background: #e4e4e4;
          }
        }
      }
    }
  }

  /* AsideBar Style */
  .aside {
    position: fixed;
    right: -81px;
    bottom: 100px;
    z-index: 10;
    transition: 0.5s;
    &.active {
      right: 0;
    }
    & > button {
      position: absolute;
      top: 50%;
      left: -30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px; height: 60px;
      border-radius: 3px 0 0 3px;
      background: #da250b;
      transform: translateY(-50%);
      img {
        width: 17px; height: 17px;
      }
    }

    .aside-list {
      display: flex;
      flex-direction: column;
      width: 81px;
      border: 1px solid #c4c4c4;
      border-right: none;
      background: #fff;
      border-radius: 5px 0 0 5px;
      overflow: hidden;
      .item {
        width: 80px;
        height: 80px;
        &-box {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 30px;
            height: 30px;
            margin-bottom: 8px;
          }
          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
          }
        }
        &.toTop {
          background: #e4e4e4;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    .footer-container {
      margin-top: calc(100vw * (150 / 1520));
      .footer-inner {
        max-width: calc(100vw * (1280 / 1520));
        padding: calc(100vw * (60 / 1520)) calc(100vw * (10 / 1520));
        .inner-list {
          &--item {
            &.left {
              max-width: calc(100vw * (652 / 1520));
              .footer-logo {
                width: calc(100vw * (320 / 1520));
                height: calc(100vw * (110 / 1520));
                margin-bottom: calc(100vw * (50 / 1520));
              }
              .text-list {
                &--item {
                  gap: calc(100vw * (8 / 1520));
                  &.heading {
                    font-size: calc(100vw * (20 / 1520));
                    line-height: calc(100vw * (24 / 1520));
                    margin-bottom: calc(100vw * (18 / 1520));
                  }

                  .text {
                    font-size: calc(100vw * (14 / 1520));
                    line-height: calc(100vw * (17 / 1520));
                  }
                }
              }
            }
            &.right {
              .info-list {
                &__item {
                  &--heading {
                    gap: calc(100vw * (15 / 1520));
                    margin-bottom: calc(100vw * (30 / 1520));
                    .heading__text {
                      font-size: calc(100vw * (20 / 1520));
                      line-height: calc(100vw * (24 / 1520));
                    }
                    .heading__phone {
                      font-size: calc(100vw * (36 / 1520));
                      line-height: calc(100vw * (43 / 1520));
                    }
                    .heading__subtext {
                      font-size: calc(100vw * (16 / 1520));
                      line-height: calc(100vw * (19 / 1520));
                    }
                  }
                  &--sns {
                    .sns-list {
                      gap: calc(100vw * (14 / 1520));
                      &--item {
                        width: calc(100vw * (34 / 1520));
                        height: calc(100vw * (34 / 1520));
                      }
                    }
                  }
                  &--family-site {
                    font-size: calc(100vw * (14 / 1520));
                  }
                }
              }
            }
          }
        }
      }
    }
    /* CustomSelect Style */
    .select-box {
      min-width: calc(100vw * (310 / 1520));
      &__inner {
        padding: calc(100vw * (13 / 1520)) calc(100vw * (16 / 1520));
        font-size: calc(100vw * (14 / 1520));
        line-height: calc(100vw * (17 / 1520));
        .title {
          .img-box {
            img {
              width: calc(100vw * (20 / 1520));
              height: calc(100vw * (20 / 1520));
            }
          }
        }
        .select-list {
          max-height: calc(100vw * (180 / 1520));
        }
        .select-item {
          .link {
            padding: calc(100vw * (9 / 1520)) calc(100vw * (6 / 1520)) calc(100vw * (10 / 1520)) calc(100vw * (16 / 1520));
          }
        }
      }
    }

    /* AsideBar Style */
    .aside {
      right: calc(100vw * (-81 / 1520));
      bottom: calc(100vw * (100 / 1520));
      & > button {
        left: calc(100vw * (-30 / 1520));
        width: calc(100vw * (30 / 1520)); height: calc(100vw * (60 / 1520));
        border-radius: calc(100vw * (3 / 1520)) 0 0 calc(100vw * (3 / 1520));
        img {
          width: calc(100vw * (17 / 1520)); height: calc(100vw * (17 / 1520));
        }
      }
      .aside-list {
        width: calc(100vw * (81 / 1520));
        border-radius: calc(100vw * (5 / 1520)) 0 0 calc(100vw * (5 / 1520));
        .item {
          width: calc(100vw * (80 / 1520));
          height: calc(100vw * (80 / 1520));
          &-box {
            img {
              width: calc(100vw * (30 / 1520));
              height: calc(100vw * (30 / 1520));
              margin-bottom: calc(100vw * (8 / 1520));
            }
            p {
              font-size: calc(100vw * (12 / 1520));
              line-height: calc(100vw * (14 / 1520));
            }
          }
        }
      }
    }
  }

/* 모바일 */
@media (max-width: 1024px) {
  .footer-container {
    margin-top: 0;
    .footer-inner {
      max-width: 100%;
      padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375));
      .inner-list {
        flex-direction: column;
        gap: calc(100vw * (40 / 375));
        &--item {
          &.left {
            max-width: calc(100vw * (652 / 375));
            .footer-logo {
              width: calc(100vw * (201 / 375));
              height: calc(100vw * (69 / 375));
              margin-bottom: calc(100vw * (40 / 375));
            }
            .text-list {
              &--item {
                gap: calc(100vw * (8 / 375));
                &.heading {
                  font-size: calc(100vw * (16 / 375));
                  line-height: calc(100vw * (24 / 375));
                  margin-bottom: calc(100vw * (18 / 375));
                }

                .text {
                  font-size: calc(100vw * (14 / 375));
                  line-height: calc(100vw * (17 / 375));
                  font-weight: 500;
                }
              }
            }
          }
          &.right {
            .info-list {
              align-items: flex-start;
              &__item {
                &--heading {
                  gap: calc(100vw * (15 / 375));
                  margin-bottom: calc(100vw * (20 / 375));
                  .heading__text {
                    font-size: calc(100vw * (16 / 375));
                    line-height: calc(100vw * (19 / 375));
                  }
                  .heading__phone {
                    font-size: calc(100vw * (32 / 375));
                    line-height: calc(100vw * (38 / 375));
                    border-bottom: none;
                    text-decoration: underline;
                  }
                  .heading__subtext {
                    font-size: calc(100vw * (14 / 375));
                    line-height: calc(100vw * (17 / 375));
                  }
                }
                &--sns {
                  .sns-list {
                    gap: calc(100vw * (14 / 375));
                    &--item {
                      width: calc(100vw * (34 / 375));
                      height: calc(100vw * (34 / 375));
                    }
                  }
                }
                &--family-site {
                  width: 100%;
                  font-size: calc(100vw * (14 / 375));
                  margin-top: calc(100vw * (20 / 375));
                }
              }
            }
          }
        }
      }
    }
  }
  /* CustomSelect Style */
  .select-box {
    min-width: auto;
    &__inner {
      width: 100%;
      padding: calc(100vw * (13 / 375)) calc(100vw * (16 / 375));
      font-size: calc(100vw * (14 / 375));
      line-height: calc(100vw * (17 / 375));
      .title {
        .img-box {
          img {
            width: calc(100vw * (20 / 375));
            height: calc(100vw * (20 / 375));
          }
        }
      }
      .select-list {
        max-height: calc(100vw * (180 / 375));
      }
      .select-item {
        .link {
          padding: calc(100vw * (9 / 375)) calc(100vw * (6 / 375)) calc(100vw * (10 / 375)) calc(100vw * (16 / 375));
        }
      }
    }
  }

  /* AsideBar Style */
  .aside {
      right: calc(100vw * (-71 / 375));
      bottom: calc(100vw * (20 / 375));
      & > button {
        left: calc(100vw * (-30 / 375));
        width: calc(100vw * (30 / 375)); height: calc(100vw * (60 / 375));
        border-radius: calc(100vw * (3 / 375)) 0 0 calc(100vw * (3 / 375));
        img {
          width: calc(100vw * (17 / 375)); height: calc(100vw * (17 / 375));
        }
      }
      .aside-list {
        width: calc(100vw * (71 / 375));
        border-radius: calc(100vw * (5 / 375)) 0 0 calc(100vw * (5 / 375));
      .item {
        width: calc(100vw * (70 / 375));
        height: calc(100vw * (70 / 375));
        &-box {
          img {
            width: calc(100vw * (30 / 375));
            height: calc(100vw * (30 / 375));
            margin-bottom: calc(100vw * (8 / 375));
          }
          p {
            font-size: calc(100vw * (12 / 375));
            line-height: calc(100vw * (14 / 375));
          }
        }
      }
    }
  }
}
`;

export { Footer };

export default {
  Footer,
};
